import React, { useContext } from 'react'
import Layout from '../../../../components/layout'
import AboutPlanTower from '../../../../components/layout/AboutPlanTower'
import CardsDisplayer from '../../../../components/layout/CardsDisplayer'
import DotDivider from '../../../../components/layout/DotDivider'
import HeroContainer from '../../../../components/layout/HeroContainer'
import MobilityConditions from '../../../../components/layout/MobilityConditions'
import MobilityPricingTable from '../../../../components/layout/MobilityPricingTable'
import RegisterButton from '../../../../components/layout/RegisterButton'
import Footer from '../../../../components/layout/Footer'
import SimilarOffers from '../../../../components/layout/SimilarOffers'
import Offer from '../../../../components/layout/Offer'
import { AppContext } from '../../../../contexts/AppContext'
import SEO from '../../../../components/seo'
import PricesTable from '../../../../components/plans/PricesTable'
import Prices from "../../../../utils/Prices"
import VerticalList from '../../../../components/layout/VerticalList'
import WarningBox from '../../../../components/layout/WarningBox'
import EnergyCycleDialog from '../../../../components/plans/EnergyCycleDialog'
import EnergyCycleComponent from '../../../../components/plans/EnergyCycleComponent'


const mobilityPlan_Cards = [{ key: "freeCards" }, { key: "imediateAccess" }, { key: "easyInvoicing" }]
const snippets = [{
    title: "whatIsIt_Title",
    text: "whatIsIt"
},
{
    title: "howItWorks_Title",
    text: "howItWorks"
},
{
    title: "evazRole_Title",
    text: "evazRole"
},]

export default function MobilityPage() {

    const { appState } = useContext(AppContext);

    return (
        <Layout>
            <SEO title={appState.i18n.heroContainers.mobilityPageTitle} />
            <HeroContainer title={appState.i18n.heroContainers.mobilityPageTitle} subtitle={appState.i18n.heroContainers.mobilityPageText} id="mobility" />
            {/* <MobilityPricingTable /> */}
            <PricesTable offPeakPrice={Prices.mobility.base.offPeak.price} peakPrice={Prices.mobility.base.peak.price} offPeakPVP={Prices.mobility.base.offPeak.pvp} peakPVP={Prices.mobility.base.peak.pvp} priceOffPeakDiscount={Prices.mobility.base.offPeak.discountOnPrice} pricePeakDiscount={Prices.mobility.base.peak.discountOnPrice}/>
            <RegisterButton />
            <WarningBox />
            {/* <DotDivider />
            <CardsDisplayer cards={mobilityPlan_Cards} />
            <DotDivider /> */}
            <AboutPlanTower page={"mobility"} snippets={snippets}/>
            <DotDivider />
            {/* <DotDivider /> */}
            <MobilityConditions />
            <RegisterButton />
            <DotDivider />
            <SimilarOffers title={appState.i18n.similarOffers.title}>
                <Offer link={"/solutions/private/powerpack"} title={appState.i18n.similarOffers.powerpacksTitle} text={appState.i18n.similarOffers.powerpacksText}/>
                <Offer link={"/solutions/plans/residential"} title={appState.i18n.similarOffers.residentialPlanTitle} text={appState.i18n.similarOffers.residentialPlanText}/>
            </SimilarOffers>
            <Footer />
        </Layout>
    )
}
